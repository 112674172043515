<template>
  <div>
    <c-table
      ref="table"
      title="실무안 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="proceedingsId"
      :columnSetting="false"
      :expandAll="true"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="add" />
          <c-btn
            v-show="editable && grid.data.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveItem"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'proceedingsWorkingPlan',
  props: {
    proceedingsTypeCd: {
      type: String,
      default: ''
    },
    searchParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        proceedingsTypeCd: null,
        committeeTypeCd: '1',
        startYmd: '',
        endYmd: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        committeeTypeCd: '1',
        title: '본안',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:90px',
            type: 'plant',
            sortable: false,
          },
          {
            required: true,
            name: 'proceedingsQuarter',
            field: 'proceedingsQuarter',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            codeGroupCd: 'SAI_PROCEEDINGS_QUARTER_TYPE_CD',
            sortable: false,
          },
          {
            name: 'proceedingsDate',
            field: 'proceedingsDate',
            label: '회의일',
            align: 'center',
            style: 'width:110px',
            type: 'date',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width:150px',
            type: 'dept',
            deptCd: 'deptCd',
            sortable: false,
          },
          {
            name: 'proceedingsContents',
            field: 'proceedingsContents',
            label: '안건',
            align: 'left',
            style: 'width:180px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'proceedingsAgreements',
            field: 'proceedingsAgreements',
            label: '합의',
            align: 'left',
            style: 'width:180px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'actionFlag',
            field: 'actionFlag',
            label: '조치여부',
            align: 'center',
            style: 'width:60px',
            type: 'select',
            comboItems: [
              { code: 'Y', codeName: '완결' },
              { code: 'N', codeName: '미결' },
            ],
            sortable: false,
          },
          {
            name: 'proceedingsRemarks',
            field: 'proceedingsRemarks',
            label: '비고',
            align: 'left',
            style: 'width:180px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'proceedingsPending',
            field: 'proceedingsPending',
            label: '미결사유',
            align: 'left',
            style: 'width:180px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'proceedingsPlanDate',
            field: 'proceedingsPlanDate',
            label: '조치계획일',
            align: 'center',
            style: 'width:110px',
            type: 'date',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:10%',
            type: 'attach',
            taskClassCd: 'OSH_PROCEEDINGS',
            keyText: 'proceedingsId',
            sortable: false,
          },
        ],
        data: [],
      },
      actionFlag: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      period: [],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.osh.proceedings.list.url;
      this.saveUrl = transactionConfig.sai.osh.proceedings.save.url;
      // code setting
      this.$set(this.searchParam, 'plantCd', this.$store.getters.user.plantCd)
      this.$set(this.searchParam, 'proceedingsTypeCd', this.proceedingsTypeCd)
      // list setting 
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd;
        this.searchParam.endYmd;
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        plantCd: this.searchParam.plantCd,
        proceedingsQuarterName: null,
        committeeTypeCd: this.tabParam.committeeTypeCd,
        proceedingsId: uuidv4(),
        deptCd: this.$store.getters.user.deptCd,
        proceedingsContents: '',
        proceedingsAgreements: '',
        actionFlag: 'Y',
        proceedingsRemarks: '',
        proceedingsPending: '',
        proceedingsDate: this.$comm.getToday(),
        proceedingsPlanDate: this.$comm.getToday(),
        useFlag: 'Y',
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
